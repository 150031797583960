.button {
  --bg-color: #{$color-primary};
  --color: #fff;
  --border-color: transparent;
  --hover-bg-color: #{$color-primary};

  --bg-color-primary: #{$color-primary};
  --color-primary: #fff;
  --border-color-primary: transparent;
  --hover-bg-color-primary: #{$color-primary};

  --bg-color-secondary: #fff;
  --color-secondary: #{$color-primary};
  --border-color-secondary: #{$color-primary};
  --hover-bg-color-secondary: #fff;

  --bg-color-tertiary: #fff;
  --color-tertiary: #{$color-primary};
  --border-color-tertiary: #{$color-primary};
  --hover-bg-color-tertiary: #fff;


  display: inline-block;
  padding: 0.5rem 1.5em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-weight: 600;

  background-color: var(--bg-color);
  color: var(--color);
  border: 1px solid var(--border-color);
  user-select: none;
  text-decoration: none;

  &--primary {
    --color: var(--color-primary);
    --bg-color: var(--bg-color-primary);
    --border-color: var(--border-color-primary);
    --hover-bg-color: var(--hover-bg-color-primary);
  }

  &--secondary {
    --color: var(--color-secondary);
    --bg-color: var(--bg-color-secondary);
    --border-color: var(--border-color-secondary);
    --hover-bg-color: var(--hover-bg-color-secondary);
  }

  &--tertiary {
    --color: var(--color-tertiary);
    --bg-color: var(--bg-color-tertiary);
    --border-color: var(--border-color-tertiary);
    --hover-bg-color: var(--hover-bg-color-tertiary);
  }

  &--danger {
    --color: #fff;
    --bg-color: #{$color-failed};
    --border-color: #{$color-failed};
    --hover-bg-color: #{$color-failed-light};
  }

  &--slim {
    padding: 0.25rem 0.5em;
    min-width: 1.75em;
  }

  &.-nobreak {
    white-space: nowrap;
  }

  &:hover {
    text-shadow: 0 1px 3px rgba(0,0,0,0.2);
    filter: brightness(120%);
  }

  &:focus {
    outline: none;
  }
  &:focus:not(:active):not(:hover) {
    border-color: #333;
    box-shadow: 0 0 0 1px #333;
    filter: brightness(102%);
  }

  &:disabled {
    --color: var(--color-primary);
    --bg-color: var(--bg-color-primary);
    --border-color: var(--border-color-primary);
    --hover-bg-color: var(--hover-bg-color-primary);
    background-color: #888;
    filter: none;
  }
}