.fieldset {
  border: none;
  background-color: #f7f7f7;
  box-shadow: 0 3px 6px rgba(0,0,0,0.2);

  legend {
    font-weight: bold;
    letter-spacing: 0.02em;
    background-color: #555;
    color: #fff;
    padding: 0.25em;
  }
}