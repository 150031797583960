.column {
  display: flex;
  flex-direction: column;
  gap: 1.0rem;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;

  &.-categories {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 3rem;
    gap: 0.75rem;

    & > button {
      width: 100%;
      height: 100%;
    }
  }
}