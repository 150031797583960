.row {
  display: flex;
  gap: 1.0rem;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;

  &.-left {
    justify-content: start;
  }

  &.-right {
    justify-content: end;
  }

  &.-top {
    align-items: start;
  }

  &.-padding-top {
    padding-top: 2rem;
  }
  &.-padding-v {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  &--cardleft {
    display: grid;
    grid-template-columns: 10rem repeat(auto-fit, minmax(0, 1fr));
    gap: 1rem;
    padding: 1rem 0;
    align-items: center;
  }

  & + & {
    padding-top: 0;
  }
}